.image-frame-svg {
    position: absolute;
    z-index: 0;
    width: 100%;
     
  }
  


  /* @media (max-width: 576px) {
    .image-frame-svg{
      transform: none;
      width: 100%;
    }

} */

/* transform: translate(8.5%, 1%) rotate(6deg);  */

@media (min-width: 56.25em) {
  .image-frame-svg {
    position: absolute;
    z-index: 0;
    width: 50%;
    transform: none; 
  }
    
}