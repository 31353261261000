
:root {
  --shape-top: 35%;
  --shape-right: 85%;
  --shape-width: 125;
  --shape-height: 200;
}

.excerpt-page{
  overflow: hidden;
  width: 100%;
}

.container {
    position: relative;
    width: 100vw;
    height: 250vw;
    overflow: hidden;
  }

.background{
  background: var(--primary-bg);
  width: 150vw;
  height: 250vw;
  position:absolute;
  transform: rotate(6deg);
  top:-15%;
  left: -25%;
  z-index: -1;
}

.background-line {
  position: absolute;
  width: 160vw; 
  height: 20px; 
  background-color: var(--secondary-bg);
}

.line1 {
  top: 37%;
  right: -30%;
  transform:  rotate(12deg);
  background-color: var(--secondary-bg); 
  box-shadow: var(--standard-shadow);
}


.quote-box {
  height: 40vw;
  position: absolute;
  top: 12%;
  left: 10%; 
  width: 95vw;
  transform: rotate(6deg); 
  background-color: var(--accent-gold); 
  box-shadow: var(--standard-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-box h2{
  color: #000000;
  position: absolute;
  width: 70vw;
  padding: 0;
  margin:0;
  max-width: 70vw;
  font-size: 1em;
  line-height: 1.3;
}

.line2 {
  top: 30%;
  right: -30%;
  transform: rotate(-8deg);
  background-color:  var(--accent-salmon); 
  box-shadow: var(--standard-shadow);
}

.quote-box_two{
  height: 15vw;
  position: absolute;
  width: 160vw; 
  top: 55%;
  left: -30%;
  transform: rotate(5deg);
  background-color: #e6dace;
  width: 160vw;
  box-shadow: var(--standard-shadow);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.quote-box_three {
  height: 15vw;
  position: absolute;
  width: 160vw; 
  top: 63%;
  right: -30%;
  background-color: #f46530;
  transform: rotate(-5deg); 
  box-shadow: var(--standard-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.quote-box_two h2{
  color: #2a2e30;
  text-align: center;
  width: 120vw;
  font-size: 2.813rem;
}

.quote-box_three h2{
  color: var(--accent-lightblue);
  position: absolute;
  font-size: 50px;
  text-align: center;
  font-size: 2.188rem;
}


.line3 {
  top: 72%;
  left: -30%;
  transform:  rotate(5deg);
  background-color: var(--accent-lightblue); 
  box-shadow: var(--standard-shadow);
}

.quote-box_four {
  height: 10vw;
  position: absolute;
  top: 77%;
  right: 1%;
  transform: rotate(-2deg); 
  background-color: var(--accent-lightblue); 
  width: 55vw;
  box-shadow: var(--standard-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.quote-box_four h1{
  color: #2a2e30;
  position: absolute;
  font-size: 1rem;
  padding: 0;
  margin:0;
  line-height: 0;
}

.quote-box_five {
  position: absolute;
  height: 10vw;
  width: 50vw;
  top: 84%;
  right: 1%;
  transform: rotate(6deg); 
  background-color: var(--accent-gold); 
  box-shadow: var(--standard-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-box_five p{
  color: #000000;
  position: absolute;
  padding: 0;
  margin:0;
  font-size: 1em;
}

.image-chains{
  position: absolute;
  transform: rotate(15deg);
  top:-3%;
  left: -3%;
  width: 50%; 
}

.image-bark{
  position: absolute;
  top: 43%;
  right:-2%;
  transform: rotate(19deg);
  width: 80%;
}

.image-note{
  position: absolute;
  top: -3%;
  right: -5%;
  width: 50%;
}

.image-compass{
  position: absolute;
  width: 45%;
  top: 72%;
  left: 1%; 
}

#text-container{
width: 100vw;
overflow: hidden;
text-align: center;
}

#text-container h1{
  font-family: kingred;
  font-size:20px;
  color:#2a2e30;
  margin: 80px 0;
}

.text-section{
  max-width: 730px;
margin: 0 auto; 
text-align: center;
color:#424242;
line-height: 25px;
letter-spacing: 1.4px;
display: block; 
}

.text-section p{
  display: block;
  margin: top; 
  text-align: start; 
  margin: 1em;
}

.first-word{
  font-family: higuen;
  font-size: 50px;
  float: left;  
  line-height: .7;  
  margin-right: 10px;
  font-style: italic;
}

.line4 {
  transform:  rotate(2deg); 
  background-color: var(--accent-lightblue); 
  box-shadow: var(--standard-shadow);
}

.line5 {
  right: -45%;
  transform: rotate(-10deg); 
  background-color: var(--secondary-bg); 
  box-shadow: var(--standard-shadow);
}

.line6{
  right: -45%;
  transform: rotate(-5deg); 
  background-color: var(--accent-gold); 
}
.line9{
  right: -45%;
  margin-top: 6em;
  transform: rotate(-5deg); 
  background-color: var(--accent-salmon); 
}

.line7 {
  left:-2%;
  transform:  rotate(5deg); 
  background-color: var(--accent-lightblue); 
  box-shadow: var(--standard-shadow);
}

.line8 {
  right: -45%;
  transform: rotate(-5deg); 
  background-color: #e6dace; 
  box-shadow: var(--standard-shadow);
}

#line-section_one{
  height: 25vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  position: relative;
}

ul {
  margin: 2em;
}

li p{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.text_two{
  margin-top:100px;
  margin-bottom: 50px;
}

@media (width >= 27.5em) {
 .quote-box{
  height: 30vw;
 }
}

@media (width >= 37.5em) {
  :root {
    --shape-top: 38%;
    --shape-width: 200;
    --shape-height: 200;
  }  
  .quote-box{
    height: 20vw;
    top:15%;
   }
   .quote-box_two h2{
    font-size: 3rem;
   }
   .quote-box_three h2{
    font-size: 3rem;
   }
   .quote-box_four h1{
    font-size: 1.5rem;
   }
   .quote-box_five p{
    font-size: 1.5rem;
   }
}
    
@media (width >= 56.25em) {
  :root {
    --shape-top: 38%;
    --shape-width: 250;
    --shape-height: 250;
  }  
  .quote-box h2{
    font-size: 1.2rem;
   }
   .quote-box_two h2{
    font-size: 4rem;
   }
   .quote-box_three h2{
    font-size: 4rem;
   }
   .quote-box_four h1{
    font-size: 2rem;
   }
   .quote-box_five p{
    font-size: 2rem;
   }
   .image-bark{
    width:60%;
   }
   .image-compass{
    width:35%;
   }
}

@media (width >=  75em) {
  :root {
    --shape-top: 38%;
    --shape-width: 400;
    --shape-height: 400;
  }  
  .background-line {
    height: 30px;
  }
  .quote-box{
    height: 15vw;
   }
  .quote-box h2{
    font-size: 1.5rem;
   }
   .quote-box_two h2{
    font-size: 6rem;
   }
   .quote-box_three h2{
    font-size: 6rem;
   }
   .quote-box_four{
    height:8vw;
   }
   .quote-box_five{
    height:8vw;
   }
   .quote-box_four h1{
    font-size: 3rem;
   }
   .quote-box_five p{
    font-size: 3rem;
   }
   .image-chains{
    width:auto;
   }
   .image-note{
    width:auto;
    right:5%;
   }
   .image-compass{
    width: auto;
   }
}
@media (width >=  87.5em) {
  :root {
    --shape-top: 32%;
    --shape-width: 400;
    --shape-height: 400;
  }  
  .quote-box {
    top: 12%;
  }
  .line2 {
    top: 23%;
  }
  .line1 {
    top: 30%;
  }
  .image-bark{
    top: 37%;
    right:-2%;
    width: 65%;
  }
  .image-chains{
    width: 45%; 
  } 
  .quote-box_two{
    height: 15vw;
    width: 160vw; 
    top: 50%;
    left: -30%;
  }
  
  .quote-box_three {
    height: 15vw;
    top:57%;
    right: -30%;
  }
    
  .line3 {
    top: 68%;
  }
  
}

@media (width >=  112.5em) {
  :root {
    --shape-top: 33%;
    --shape-width: 500;
    --shape-height: 500;
  }
  .quote-box{
    height: 10vw;
   }
   .quote-box_two h2{
    font-size:8rem;
   }
   .quote-box_three h2{
    font-size:8rem;
   }
   .quote-box_four{
    height:8vw;
   }
   .quote-box_five{
    height:8vw;
   }
   .image-compass{
    top: 72%;
    left: 1%;
    width: 40%;
   }
}


@media (width >=  120em) {
  .image-note{
    top: -1%;
    width: 35%;
   }
   .text-section p{
    font-size: 1rem;
  }
}

@media (width >=  160em) {
  :root {
    --shape-top: 33%;
    --shape-width: 750;
    --shape-height: 750;
  }
  .background-line {
    height: 45px;
  }
  .quote-box h2 {
    font-size: 2rem;
  }
  .quote-box_two h2{
    font-size:12rem;
   }
   .quote-box_three h2{
    font-size:12rem;
   }
   .quote-box_two{
    height:10vw;
   }
   .quote-box_three{
    height:10vw;
   }
   .quote-box_four h1{
    font-size: 6rem;
   }
   .quote-box_five p{
    font-size: 6rem;
   }
   .line9 {
    right: -45%;
    margin-top: 10em;
  }
}

@media (width >=  240em) {
  .quote-box h2 {
    font-size: 3rem;
  }
  .quote-box_two h2{
    font-size:14rem;
   }
   .quote-box_three h2{
    font-size:14rem;
   }
}

@media (prefers-color-scheme: dark) {
/* Dark mode styles */
}



