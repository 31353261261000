:root {
  --primary-bg: #033f57;
  --secondary-bg: #345c72;
  --accent-gold: #d3a518;
  --accent-lightblue: #d4edf4;
  --accent-salmon: #ff9e7a;
  --accent-orange: #f46530;
  --dark-bg:#2a2e30;
  --standard-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Higuen;
  src: url('../public/fonts/Higuen.otf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html { scroll-behavior: smooth; }

body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

h1{
  font-family: Higuen;
  font-size: 5rem;
  font-weight: 500;
  line-height: 1.2;
  /* margin-top: 50px; */
  letter-spacing: .2rem;
}

h2 {
  font-family: "Lexend", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.875em;
  letter-spacing: 1.4px;
}

p {
  font-family: "Lexend", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 1rem;
}

.button {
  text-decoration:none !important;
  padding: 8px 20px;
  border-radius:36px;
  border: none;
  cursor: pointer;
  letter-spacing: 1.4px;
  font-size: 1.25rem;
}

svg {
  display: block;
  margin: 0;
  padding: 0;
  line-height: 0;
  width: 100%;
  height: auto;
}

.svg-container {
  height:300px;
  width: 100vw;
}


.button:hover {
  transition: all 150ms linear;
  opacity: 0.85;
}

.button:active {
  transition: all 150ms linear;
  opacity: 0.75;
}

.button:focus {
  outline: 1px dotted #959595;
  outline-offset: -4px;
}



#navbar {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 1000; 
}

.dot {
  height: 2em;
  width: 2em;
  background-color: var(--accent-lightblue);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-container {
  display: flex;
  justify-content: center; 
  height: 3.75em; 
}

#navbar.hidden {
  top: -5em; 
}

.navbar-name h1 {
  display: none;
}

.navbar-links {
  display: none;
}

.navbar-hamburger {
  position: absolute;
  right: 20px; 
  top: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--dark-bg); 
  z-index: 10000;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-bg); 
  z-index: 101; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 100px;
  transition: top 0.3s ease-in-out;
  
}

.fullscreen-menu h2:first-of-type {
  text-decoration: underline;
}

.menu-item {
  font-size: 2em;
  font-weight: 200;
  color: #fff; 
  text-align: center;
  width: 95vw;
  margin: 30px 0;
}

.menu-item a {
    text-decoration: none;
    color: #fff;
}

.menu-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

#landingpage {
  width: 100%;
  background-color: #d4edf4;
}

.landingpage-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 100vh;
}

.left-container,
.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1; 
  width: 100%;
}


.right-container {
  justify-content: flex-start;

}

.text-container {
  max-width: 55.625em;
}

.small-text_landing {
  font-size: 3.75rem; 
  position: relative; 
  top: -0.2em; 
}

.small-text {
  font-size: 1.875rem; 
  position: relative; 
  top: -0.3em; 
}

.left-container {
  display: flex;
  position: static;
  justify-content: center;
  align-items: center;
  text-align: end;
  height: 100%;
  z-index: 1;
}

.left-container picture {
  /* position:absolute; */
  width:100%; 
  height: auto; 
  z-index: 1;
  text-align: center;
}
.landing-image {
  width:85%; 
  height: auto; 
  z-index: 1;
  max-width: 100%; 
  max-height: 100%
}

.right-container h1{
  font-family: Higuen;
  color: var(--accent-orange);
  font-size: 5rem;
  font-weight: 200;
  line-height: 1.2;
  margin-top: 50px;
  text-align: center;
}

.right-container div{
  display: flex;
  flex-direction: row;
  margin-top: 3.125em;
  justify-content: space-around;
  width: 100%;
  max-width: 25em;
  font-size: 1rem;
}

#LandingBook{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 3.75rem 5%;
  height: 75%;
  color: var(--dark-bg);
  text-align: center;
  width: auto;
}

.LandingBook_title{
  color: #fff;
  width: 50vw;
}

.LandingBook_text{
  text-align: start;
  margin-top: auto;
}

.LandingBook_button{
  background: #f46530;
  color: #fff;
  margin-top: 50px;
}

.LandingBook_containers{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
}

.LandingBook_right-container{
  width: 100%;
  order:1;
  max-width: 37.5em;
}

.LandingBook_right-container-image{
  width: 90vw; 
  max-width: 37.5em;
  height: auto;
  object-fit: contain;
}

.LandingBook_left-container{
  line-height:1.56em;
  letter-spacing: 1.4px;
  width: 95vw;
  max-width: 37.5em;
  text-align: center;
  order:2;
}

.LandingBook_right-container p{
margin-top: 10px;
}

.LandingBook_left-container h1{
font-size: 2.8rem !important;
margin:0;
margin: 1em 0;
}

.LandingBook_left-container p{
margin-bottom: 3.125em;
}


.LandingBook h2{
  max-width: 50vw;
  font-size: 1.9rem;
}

.LandingBook_image-container{
  width: 50vw;
  height: 60vh;
  max-width: 1000px;
  max-height: 700px;
}

.section_button{
  background:#2a2e30; 
  color: #fff;
  
}

#booktitle{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  height: auto;
  background-color: #2a2e30;
  text-align: center;
}

.booktitle_title{
  color: #fff;
  max-width: 95vw;
  margin-top: 1.25em; 
}
.booktitle{
  width: 100%;
  display: flex;
  justify-content: center;
}

.booktitle-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.booktitle_svg{
  padding: 10% 0;
}

.booktitle h1{
  font-size: 4rem;
  color: #d3a518;
}

.small-text-book_landing {
  font-size: 3rem; 
  top: -0.2em;  
  position: relative; 
}

.booktitle_text{
  text-align: center;
  margin-top: auto;
  color: #fff;
  width: 95%;
  /* margin: 7.5em 0 1.875em 0; */
}

.booktitle_text h2{
  font-weight: 200;
}

.booktitle_button-container{
  width: 100%;
  padding: 35px 0;
}



.booktitle_button{
  background: #f46530;
  color: #fff;
}

#review{
  opacity: 1;
  max-height: none;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#review.show {
  opacity: 1;
  max-height: none; 
}

.review_title{
  text-align: center;
  font-size: 3.125rem;
  line-height: 3.125rem;
  letter-spacing: 2.5px;
  font-weight: 400;
  max-width: 100vw;
  margin: 5%;
  margin-bottom: 15%;
}

.review_containers{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95vw;
  gap: 1.875em ; 
}

.review_left-container {
  order:2;
  width:95vw;
  display: flex;
  justify-content: center;
  max-width: 37.5em;
}

.review_left-container-text {
  width: 95vw;
  line-height: 25px;
  letter-spacing: 1.4px;
  margin-bottom:3.125em; 
}

.review_left-container-text p:nth-of-type(n+2) {
  margin-top: 1em;
}

.review_left-container-text p:last-of-type{
  text-align: end;
}

.review_right-container {
  order: 1;
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.review_right-container picture{
  text-align: center;
}

.review_right-container-image {
  width: 95vw; 
  max-width: 34.375em;
  height: auto;
  object-fit: contain;
  align-self:flex-start;
}

.review_calltoaction{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d4edf4;
  text-align: center;
  padding-top: 3.125em;
  margin-inline: 5%;
}

.review_calltoaction h2{
  margin-top: 1em;
  margin-inline: 5%;
}
.review_calltoaction h2:last-of-type{
  margin-top: 1em;
}

.review_calltoaction-button{
  margin: 1.6em 0;
  background: #f46530;
  color: #fff;
}

.belowreview_calltoaction{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6.25em ;
}
.belowreview_calltoaction h2{
  margin: 2em 0;
  text-align: center;
  margin-inline:5%;
}

.belowreview_calltoaction-button{
  background: var(--accent-orange);
  color: #fff;
  width: 95%;
  text-align: center;
  margin: 1em 0;
  max-width: 22.813em ;
}

.review_bottomimage{
  width: 95vw;
  height: auto;
  max-width: 62.5em;
  max-height: 43.75em;
  background-image: url("../public/images/IMG01.jpg");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: contain; 
}

#list{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
  background-color:#345c72;
  color: #fff; 
}

.list_containers{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 37.5em;
}

.list_right-container{
  line-height: 25px;
  letter-spacing: 1.4px;
  text-align: start;
}
.list_right-container{
  line-height: 25px;
  letter-spacing: 1.4px;
  text-align: start;
  width: 95vw;
  max-width: 37.5em;
  font-weight: 200;
  margin-bottom: 3.125em;
}
.list_right-container p{
padding: 10px 0;
}

.list_container-SVG{
  margin:55px 0;
}

.list_container-SVG svg{
  transform: rotate(90deg);
  height:auto;
}

#about{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  margin: 6.25em 0 9.4em 0;
}

.about_containers{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  gap: 2rem;
  margin:5%;
}

.about_left-container-image{
  width: 90vw; 
  max-width: 37.5em;
  height: auto;
  object-fit: cover;
  object-position: top;
}

.about_right-container{
  width:90vw;
  max-width: 37.5em;
  line-height: 1.563em;
  letter-spacing: 1.4px;
  text-align: start;
}

.about_right-container p{
  margin-top: 1.56em;
  text-align: start;
}

.about_right-container h2:nth-of-type(3) {
  color: var(--accent-orange);
}

.about_right-container-image{
  width: 90vw; 
  max-width: 37.5em;
  height: auto;
  object-fit: cover;
  object-position: top;
}

#checkout{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: auto;
  background-color: var(--accent-lightblue);
  margin: 0;
  padding: 40px 0 60px 0;
  position: relative; 
  overflow: visible;
}

.checkout_containers{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95vw;
  height: auto;
  gap: 1.875em;
}

.option-label {
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding:10px 5px;
  margin: 10px 0;
}


.option-container input[type="radio"]:checked + .option-label:hover {
  cursor: pointer;
}
.option-container input[type="radio"]:checked + .option-label {
  box-shadow: 0 0 0 2px #f46530, 0 0 8px rgba(244, 101, 48, 0.6);
  border-radius: 3px;
  transition: all 0.3s ease;
}
.option-container input[type="radio"] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}


.checkout_right-container-image{
  width: 95vw; 
  max-width: 37.5em;
  height: auto;
  object-fit: contain;
}

.checkout_right-container{
  display: flex;
  flex-direction: column;
  max-width: 37.5em;
  line-height: 25px;
  letter-spacing: 1.4px;
  text-align: start;
  height: auto;
  width: 100%;
}

.checkout_right-container-bottom {
  position: relative; 
  width: 100%;
}

.checkout_right-container-bottom p{
  margin: 0 0 15px 0;
}

.checkout_right-container h2{
margin-bottom: 10px;
font-size: 1.25rem;
text-align: center;
}
.checkout_right-container h2:nth-of-type(3) {
  color: var(--accent-orange);
}

.option-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.checkout-button{
  margin: 1.563em 0;
  background: var(--accent-orange);
  color: #fff;
  width: 100%;
  border-radius: 3px;
  font-weight: 300;
  margin:2.5em 0;
}

.product-format-selector{
  margin: 15px 0;
}


input {
  margin:15px 0px 15px 0;
}

.checkout_right-container-bottom p{
  margin:15px 0;
  font-weight: 300;
}


#footer{
  height: auto;
  width: 100%;
  background-color: #2a2e30;
  color:#fff;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.footer-left_container{
  order:2;
  height:auto;
  width: 95vw;
  margin: 4rem 0;
}
.footer-left_container h1{
  font-size:2.2rem;
  margin-bottom: 30px;
}

.footer-left_container p{
  font-size: 1rem;
  font-weight: 200;
}

.footer-right_container {
  text-align: center;
  height: 50%;
  padding-top: .625em;
  order:1;
  width: 95%;
  margin: 4rem 0;
}
.footer-right_container p{
  font-weight: 200;
  padding: .625em;
  order:1;
}

.footer-right_container h2{
  text-align: center;
  padding: 20px;
  text-decoration: underline;
}

.small-text_footer {
  font-size: 1.875rem;
  position: relative;
  top: -0.1em;
}

#footer a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.3s ease;
  border-radius: 4px;
}

#footer a:hover {
  transition: 0.75s;
  color: var(--accent-orange);
}


.svg_bottom{
  width: 100%;
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}







 @media (width >= 37.5em) {

  .small-text-book_landing {
    font-size: 4rem;
    top: -0.15em;
  }
  .small-text_landing {
    font-size: 3rem;
    position: relative;
    top: -0.2em;
  }

  .right-container h1 {
    font-size: 4rem;
  }
  .booktitle h1 {
    font-size: 5rem;
  }
 }
    
@media (width >= 56.25em) {

  .landingpage-container{
    flex-direction: row;
  }

  .review_containers{
    width: 75vw;
  }
}

@media (width >=  75em) {
  .booktitle h1 {
    font-size: 7rem;
  }
  .small-text-book_landing {
    font-size: 6rem;
    top: -0.1em;
  }
  #navbar{
    background-color: rgba(212, 237, 244, .9);
  }
  .navbar-name{
      display: block;
  }
  .navbar-name h1 {
    display: block;
    font-size: 2.8rem; 
    font-family: Higuen; 
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: .2rem;
    color: var(--accent-orange);
  }
  
  .navbar-links {
    position: absolute;
    right: 80px; 
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
  
  .navbar-link {
    color: #000;
    text-decoration: none;
    margin: 0 15px; 
    font-size: 1.125rem; 
    position: relative;
    overflow: hidden; 
    width: 3.75em;
    height: 1.25em;
    transition: 0.75s;
  }
  
  .navbar-link:hover {
    cursor: pointer;
    color: var(--accent-orange);
  }
  .link1:hover {
    width: 125px;
    transition: 0.75s;
  }
  .link2:hover {
    width: 210px;
    transition: 0.95s;
  }
  .link3:hover {
    width: 150px;
    transition: 0.95s;
  }
  .navbar-hamburger{
    display: none;
  }
}

@media (width >=  75em) {
  .LandingBook_containers {
    flex-direction: row;
    gap:  4.375em;
    align-items: flex-start;
  }
  .LandingBook_left-container{
    order:1;
    text-align: start;
  }
  .LandingBook_left-container h1 {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .LandingBook_right-container{
    order:2;
  }
  .about_containers {
    flex-direction: row;
    align-items: flex-start;
    gap:  4.375em;
  }
  .review_title{
    max-width: 50%;
    margin-top: 0;
    margin-bottom: 5%;
  }
  .review_containers{
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .review_left-container{
    order:1;
  }
  .review_right-container{
    order:2;
  }
  .list_containers{
    flex-direction: row;
    max-width: 75em;
  }
  .list_container-SVG{
    margin:0 55px;
  }
  .list_container-SVG svg {
    transform: rotate(0deg);
    height: 200px !important;
    width: 200px !important;
  }
  #checkout{
    padding:  4.375em 0;
  }
  .checkout_containers{
    flex-direction: row;
    align-items: flex-start;
    gap: 4.375em;
  }
  
  .option-container {
    justify-content: flex-end;
  }
  .option-label {
    width: 50%;
  }
  .checkout-button{
    max-width: 22.813em;
    align-self: flex-end;
  }

  #footer{
    flex-direction: row;
  }
}

@media (width >=  160em) {
  h2{
    font-size: 2.5rem;
    line-height: 1.2em;
  }
  p{
    font-size: 1.5rem;
  }
  .button {
    font-size: 2rem;
  }
  .right-container h1 {
    font-size: 8rem;
  }
  .small-text_landing {
    font-size: 6rem;
    position: relative;
    top: -0.2em;
  }
  .right-container div{
    margin-top: 6.25em;
    max-width: 50em;
    font-size: 1rem;
  }
  .LandingBook_left-container h1 {
    font-size: 4.6rem !important;
  }

  .small-text {
    font-size: 3.5rem;
    position: relative;
    top: -0.2em;
  }
  #landingbook{
    display: flex;
    margin:0%;
    justify-content: center;
    margin: 3.75rem 0;
  }
  .LandingBook_left-container {
    max-width: 50em;
    line-height:2.5em;
    letter-spacing: 2px;
  }
  .LandingBook_left-container p {
    margin-bottom: 2em;
  }

  .LandingBook_right-container{
    max-width: 50em;
  }
  .LandingBook_right-container-image {
    max-width: 50em;
  }
  .about_containers {
    flex-direction: row;
    align-items: flex-start;
    gap:  4.375em;
  }

  .about_left-container-image{
    max-width: 50em;
  }
  
  .about_right-container{
    width:90vw;
    max-width: 50em;
    line-height: 2.5em;
    letter-spacing: 2px;
  }

  .booktitle h1 {
    font-size: 12rem
  }
  .small-text-book_landing {
    font-size: 9rem;
    top: -0.22em;
  }

  .booktitle_svg svg{
    height: 200px !important;
    width: 200px !important;
  }

  .booktitle_text h2 {
    line-height: 2em;
  }

  .review_containers {
    gap:  4.375em;
  }

  .review_left-container {
    max-width: 50em;
  }

  .review_left-container-text {
    line-height: 2.5em;
    letter-spacing: 2px;
  }
  .review_right-container-image {
    max-width: 50em;
  }
  .list_containers {
    flex-direction: row;
    max-width: 100em;
  }
  .list_right-container p {
    padding: 10px 0;
    line-height: 1.6em;
  }
  .about_right-container p {
    margin-top: 1.2em;
  } 
  #checkout {
    padding: 8em 0;
  }
  .checkout_right-container {
    display: flex;
    flex-direction: column;
    max-width: 50em;
    line-height: 2.5em;
    /* letter-spacing: 1.4px; */
  }
  .checkout_right-container-image {
    max-width: 50em;
}
  .checkout-button {
    align-self: center;
  }
  .navbar-name h1 {
    font-size: 4rem;
  }
  .navbar-container {
    display: flex;
    justify-content: center;
    height: 5em;
  }
  .navbar-link {
    width: 5.625em;
    height: 1.875em;
  }
  .link1:hover {
    width: 200px;
    transition: 0.75s;
    color: var(--accent-orange);
  }
}

@media (width >=  240em) {
  h2{
    font-size: 3.5rem;
    line-height: 1.2em;
  }
  p{
    font-size: 2.5rem;
    line-height: 1.2em;
  }
  .button {
    font-size: 3rem;
  }
  .right-container h1 {
    font-size: 12rem;
  }
  .small-text_landing {
    font-size: 9.5rem;
    top: -0.2em;
  }
  .LandingBook_containers {
    gap: 8em;
  }
  .LandingBook_right-container{
    max-width: 70em;
  }
  .LandingBook_left-container {
    max-width: 70em;
    line-height:2.5em;
    letter-spacing: 2px;
  }
  .LandingBook_right-container-image {
    max-width: 70em;
  }
  .LandingBook_left-container h1 {
    font-size: 6.6rem !important;
  }
  .small-text {
    font-size: 5.5rem;
    position: relative;
    top: -0.15em;
  }
  .about_containers {
    gap: 8em;
  }
  .about_left-container-image{
    max-width: 70em;
  }
  .about_right-container{
    width:90vw;
    max-width: 70em;
    line-height: 2.5em;
    letter-spacing: 2px;
  }
  .booktitle h1 {
    font-size: 20rem
  }
  .small-text-book_landing {
    font-size: 16rem;
    top: -0.18em;
  }
  .review_containers {
    gap:  8em;
  }
  .review_left-container {
    max-width: 70em;
  }
  .review_left-container-text {
    line-height: 2.5em;
    letter-spacing: 2px;
  }
  .review_right-container-image {
    max-width: 70em;
  }

  .list_right-container{
    max-width: 70em;
  }
  
  .list_containers {
    max-width: 140em;
  }
  .list_container-SVG svg {
    transform: rotate(0deg);
    height: 500px !important;
    width: 500px !important;
  }
  .list_left-container h1{
    font-size: 8rem;
  }
  .list_right-container p{
    margin-bottom: 1.5em;
    font-size: 3rem;
  }
  .review_bottomimage{
    max-width: 140em;
    max-height: 140em;
  }
  #checkout {
    padding: 8em 0;
  }
  .checkout_right-container {
    max-width: 70em;
  }
  .checkout_right-container-image {
    max-width: 70em;
  }
  .checkout_right-container-bottom p {
    margin: 2em 0;
    font-weight: 300;
  }
  .checkout_right-container h2 {
    font-size: 4rem;
  }
  .navbar-name h1 {
    font-size: 6rem;
  }
  .navbar-container {
    height: 7.5em;
  }
  .navbar-link {
    width: 9em;
    height: 3em;
  }
  .link1:hover {
    width: 350px;
    transition: 0.75s;
    color: var(--accent-orange);
  }
  .link2:hover {
    width: 500px;
    transition: 0.75s;
    color: var(--accent-orange);
  } 
  .link3:hover {
    width: 375px;
    transition: 0.95s;
  }
  #navbar.hidden {
    top: -7.5em; 
  }

  #footer a{
    font-size: 2rem;
  }
  .footer-left_container h1{
    font-size:7rem;
  }
  .footer-left_container p{
    font-size:2rem;
  }
  .small-text_footer {
    font-size: 6rem;
    position: relative;
    top: -0.15em;
  }
}

  @media (prefers-color-scheme: dark) {
    /* Dark mode styles */
  }